var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Edit Stop Words" }
      }),
      _c(
        "gov-back-link",
        { attrs: { to: { name: "admin-index-search-engine-stop-words" } } },
        [_vm._v("Back to stop words")]
      ),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Stop Words")
                  ]),
                  _c("gov-warning-text", [
                    _vm._v(
                      "\n          Please note, uploading a new set of stop words may cause the search\n          functionality to stop working for a short period of time. Please do\n          not replace during peak hours.\n        "
                    )
                  ]),
                  _c("gov-heading", { attrs: { size: "m" } }, [
                    _vm._v("Edit Stop Words")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "Update the stop words used when searching for services."
                    )
                  ]),
                  _c("stop-words-form", {
                    attrs: { errors: _vm.form.$errors, stopWords: _vm.file },
                    on: {
                      "update:stopWords": function($event) {
                        _vm.file = $event
                      },
                      "update:stop-words": function($event) {
                        _vm.file = $event
                      },
                      clear: function($event) {
                        return _vm.form.$errors.clear($event)
                      }
                    }
                  }),
                  _c("gov-section-break", { attrs: { size: "l" } }),
                  _vm.form.$submitting
                    ? _c(
                        "gov-button",
                        { attrs: { disabled: "", type: "submit" } },
                        [_vm._v("Updating...")]
                      )
                    : _c(
                        "gov-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("Update")]
                      ),
                  _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }